<template>
  <div>
    <b-form-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-checkbox
            id="apply-security-method"
            name="apply-security-method"
            v-model="formPayload.applySecurityMethod"
            :disabled="multilingualToggle"
            @change="getSecurityMethods()"
          >
            <h4 class="mt-1 page-sub-header">
              Apply a security method
              <InheritText v-if="multilingualToggle" />
            </h4>
          </b-form-checkbox>
          <p class="mb-0 ml-4 font-14">Select a security method to use when connecting to the configured API.</p>
        </b-form-group>
      </b-col>
    </b-form-row>
    <template v-if="formPayload.applySecurityMethod">
      <p>Select an existing security method or create a new one</p>
      <b-form-row>
        <b-col cols="12" sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="security-methods-dropdown"
                class="form-control"
                v-model="formPayload.securityMethodId"
                :disabled="multilingualToggle"
                @input="formPayload.securityMethodId = $event"
                :class="{
                  'is-value-exist': formPayload.securityMethodId,
                  'is-invalid': !formPayload.securityMethodId && formSubmitted,
                }"
              >
                <b-form-select-option :value="security.id" v-for="(security, index) in securityMethods" :key="index">{{
                  security.methodName
                }}</b-form-select-option>
              </b-form-select>
              <label for="security-methods-dropdown"
                >Select security method
                <InheritText v-if="multilingualToggle" />
              </label>
              <b-form-invalid-feedback class="d-block" v-if="!formPayload.securityMethodId && formSubmitted">Security method required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <b-button
              type="button"
              id="add-security-method-btn"
              v-activeBlur
              variant="primary"
              @click="showAddEditSecurityMethodSideBar = true"
              aria-controls="add-edit-security-method-sidebar"
              :aria-expanded="showAddEditSecurityMethodSideBar"
              :disabled="multilingualToggle"
              >Add security method <BIconPlus scale="1.5" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
    <SideBar
      :showSideBar="showAddEditSecurityMethodSideBar"
      :componentName="'AddEditSecurityMethod'"
      :headerTitle="'Add security method'"
      :isCloseBtn="false"
      :isResetBtn="true"
      :isSaveBtn="true"
      @closeSideBar="showAddEditSecurityMethodSideBar = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus } from 'bootstrap-vue'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
export default {
  name: 'ApplySecurityMethodForm',
  setup() {
    const { response, callApi } = useAxios()
    return { response, callApi }
  },
  props: {
    formPayload: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    culture: {
      type: String,
    },
    defaultCulture: {
      type: String,
    },
  },
  data() {
    return {
      showAddEditSecurityMethodSideBar: false,
    }
  },
  components: {
    BIconPlus,
    SideBar: () => import('@/components/common/SideBar.vue'),
    InheritText: () => import('./InheritText.vue'),
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    ...mapState({
      securityMethods: (state) => state.settings.securityMethods,
      selectedTenant: (state) => state.common.selectedTenant,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  mounted() {
    this.getSecurityMethods()
  },
  methods: {
    async getSecurityMethods() {
      if (this.formPayload.applySecurityMethod) {
        const url = this.$route.params.tenantID
          ? `${ServiceUrls.getSecurityMethods}/${this.$route.params.tenantID}`
          : tenantSpecificURL(ServiceUrls.getSecurityMethods)
        await this.callApi({ method: 'get', url })
        if (this.response) {
          this.$store.commit('settings/setSecurityMethods', this.response)
        }
      } else {
        this.formPayload.securityMethodId = null
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.formPayload.securityMethodId = null
      this.getSecurityMethods()
    },
  },
}
</script>
